import React, { Component } from "react";
import FeatherIcon from "feather-icons-react";
import NavbarDashboardMobileAdmin from "./components/NavbarDashboardMobile";
import HeaderDashboardAdmin from "./components/HeaderDashboard";
import NavbarVerticalAdmin from "./components/NavbarVertical";
// import ModalDetailsApprenant from './components/ModalDetailsApprenant';
// import { NavLink } from 'react-router-dom';
import { Tag, Modal, Alert, Button } from "rsuite";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
  ButtonGroup,
  ExportCSVButton,
} from "react-bootstrap-table";
// import $ from 'jquery'
import api from "./../http/global-vars";
import axios from "axios";
import MessageAllPage from "./components/MessageAllPage";
// import ImportApprenant from './components/ImportApprenant'
// import { ToastContainer, toast } from 'react-toastify';
import { formatDate } from "../utils";
import ListeIdentificationSkeleton from "./AdminSkeletons/ListeIdentificationSkeleton";
import _ from "lodash";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const optionFilter = {
  true: "Oui",
  false: "Non",
};

export default class ListeIdentificationV2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      isPageLoading: true,
      userIndentifications: [],
      users: [],
      userInfos: [],
      identificationInfos: [],
      isIdentified: false,
      listIdentified: [],
      listing: [],
      identifiedList: [],
      load: false,
      show: false,
      item: {},
    };
  }

  componentDidMount() {
    this.onGetListeFeedbacks();
    this.onGetListeIdentification();
    this._getListEvaluated();
  }

  onGetUserList = () => {
    var api = this.state.api;
    var config = this.state.requestConfig;

    axios.get(`${api}user/`, config).then((response) => {
      if (response.status === 200 || response.status === 201) {
        console.log(response);
        this.setState({
          users: response.data.results,
        });
      }
    });
  };

  _getListEvaluated = async () => {
    const url = api + "feddback";
    const { requestConfig } = this.state;
    this.setState({ load: true });
    try {
      const req = await axios.get(url, requestConfig);
      this.setState({
        identifiedList: req.data["results"],
        load: false,
      });
      /* this.setState({
                identifiedList: _.uniqBy(req.data['results'], function (e) {
                    return e.user.id;
                }), load: false
            }) */
    } catch (error) {
      console.log(error["message"]);
    }
  };

  onGetListeIdentification = async () => {
    var api = this.state.api;
    var config = this.state.requestConfig;

    await axios
      .get(`${api}identified/`, config)
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });

        if (response.status === 200 || response.status === 201) {
          // console.log("identified", response)
          this.setState({
            userIndentifications: response.data.results,
          });

          this.state.userIndentifications.forEach((item) => {
            this.state.listing.forEach((it) => {
              console.log("display");
              if (item.id === it.id) {
                item["is_identified"] = true;
              }
            });
          });
          this.setState({
            isPageLoading: false,
          });
          // console.log(this.state.userIndentifications)
        }
      })
      .catch((error) => {
        this.setState({
          isPageLoading: false,
        });
        console.log(error);
      });
  };

  onRenderUserFullname = (cell, row) => {
    return (
      <span
        className="linkable"
        onClick={() => this.onGetUserInfos(row)}
        data-toggle="modal"
        data-target=".showUserInfosModal"
      >
        {row.first_name + " " + row.last_name}
      </span>
    );
  };

  onRenderConnexionStatus = (cell, row) => {
    if (row.first_connexion === false) {
      return (
        <span className="badge-success gfa-badge-success">déjà connecté</span>
      );
    } else {
      return (
        <span className="badge-danger gfa-badge-danger">jamais connecté</span>
      );
    }
  };

  onGetUserInfos = (infos) => {
    this.setState({
      userInfos: infos,
    });
    console.log(infos);
    this.onGetUserIdentifiedInfos(infos.id);
  };

  onGetUserIdentifiedInfos = (userID) => {
    var api = this.state.api;
    var config = this.state.requestConfig;

    axios
      .get(`${api}feedback/${userID}`, config)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          // console.log(response)
          this.setState({
            identificationInfos: response.data,
            isIdentified: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isIdentified: false,
        });
      });
  };

  onGetListeFeedbacks = async () => {
    var api = this.state.api;
    var config = this.state.requestConfig;

    await axios
      .get(`${api}feddback/`, config)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          // console.log(response)
          this.setState({
            listIdentified: response.data.results,
          });
          // console.log(this.state.listIdentified)
          var listing = [];
          this.state.listIdentified.forEach((item) => {
            listing.push(item.user);
          });
          this.setState({
            listing: listing,
          });
          // console.log(response.data.results)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => this.handleClearButtonClick(onClick)}
      />
    );
  };

  createCustomButtonGroup = (props) => {
    let formatter = new Intl.DateTimeFormat("FR", {
      month: "long",
    });
    return (
      <>
        <ButtonGroup className="export-btn" sizeClass="btn-group-md">
          {props.exportCSVBtn}
        </ButtonGroup>
        <ExcelFile
          className="btn-group btn-group-md export-btn el-export"
          filename="Fiche_identification_eligibles"
          element={
            <button className="export-xls-btn py-2 px-4 ml-2">
              <FeatherIcon
                className="icon-btn-add-dashboard-orange fs-15 fw-400"
                icon="download"
              />
              Utilisateurs éligibles (
              {
                this.state.identifiedList.filter(
                  (data) => data.user.eligible === true
                )?.length
              }
              )
            </button>
          }
        >
          <ExcelSheet
            data={this.state.identifiedList.filter(
              (data) => data.user?.eligible === true
            )}
            name="Fiche_identification"
          >
            <ExcelColumn
              label="Prénon&Nom"
              value={(col) => col.user.first_name + " " + col.user.last_name}
            />
            <ExcelColumn
              label="Mois"
              value={(col) => formatter.format(Date.parse(col.created_at))}
            />
            <ExcelColumn
              label="Année"
              value={(col) => moment(col.created_at).format("YYYY")}
            />
            <ExcelColumn label="Pays de provenance" value="pays_provenance" />
            <ExcelColumn label="Genre" value={(col) => col.user.sexe} />
            <ExcelColumn
              label="Adresse Email"
              value={(col) => col.user.email}
            />
            <ExcelColumn label="Adresse" value={(col) => col.user.adresse} />
            <ExcelColumn label="Téléphone" value={(col) => col.user.phone} />
            <ExcelColumn
              label="Date de naissance"
              value={(col) => col.user.date_naissance}
            />
            <ExcelColumn label="École" value={(col) => col.user.school} />
            <ExcelColumn label="Badges" value={(col) => col.user.badge} />
            <ExcelColumn
              label="Certificat"
              value={(col) => col.user.certificate}
            />
            <ExcelColumn label="Migrant" value="migrant" />
            <ExcelColumn label="Profession" value="profession" />
            <ExcelColumn label="Situation économique" value="Situation_eco" />
            <ExcelColumn label="Revenu journalier" value="revenu_jour" />
            <ExcelColumn label="Début" value={(col) => col.user.date_debut} />

            <ExcelColumn label="Fin" value={(col) => col.user.date_fin} />
            <ExcelColumn label="Secteur" value="secteur" />
            <ExcelColumn label="Nbr.d’employés" value="nbr_employe" />
            <ExcelColumn
              label="Nbr. employés temporaires"
              value="nbr_employe_temp"
            />
            <ExcelColumn label="Type d’énergie" value="type_energie" />

            <ExcelColumn label="niveau d’étude" value="niveau_etude" />

            <ExcelColumn
              label="Certificat/diplôme officiel"
              value="nom_diplome"
            />
          </ExcelSheet>
        </ExcelFile>
      </>
    );
  };

  onActionFormatter = (cell, row) => {
    return (
      <div className="actions-buttons-container">
        <button
          className="btn btn-primary gfa-btn-action-primary"
          onClick={() => this.onGetUserInfos(row)}
          data-toggle="modal"
          data-target=".showUserInfosModal"
        >
          <FeatherIcon
            icon="eye"
            className="regenerate-apprenant-password-icon icon-eye"
          />
        </button>
      </div>
    );
  };

  // onGetFeddbacks = () => {
  //     var api = this.state.api
  //     var config = this.state.requestConfig
  //     await axios.get(`${api}feedback/`, config)
  //         .then(response => {
  //             if(response.status === 200 || response.status === 201){
  //                 console.log(response.data.results)
  //                 this.setState({
  //                     listingIdentfied: response.data.results
  //                 })
  //             }
  //         })
  //         .catch(error => {
  //             console.log(error)
  //         })
  // }

  onRenderIdentificationStatus = (cell, row) => {
    // var list = this.state.listIdentified
    // var isOK = false
    // this.state.listIdentified.forEach((item) => {
    //     if(item.user.id === cell){
    //         isOK = true
    //     }
    // })
    // if(isOK === true){
    //     return 'ok'
    // }else{
    //     return 'nok'
    // }
  };
  showFullname = (cell, row) => {
    //console.log('row cell', row, cell)
    return row.user.first_name + " " + row.user.last_name;
  };

  showGender = (cell, row) => {
    //console.log('row cell', row, cell)
    return row.user.sexe;
  };

  showEmail = (cell, row) => {
    //console.log('row cell', row, cell)
    return row.user.email;
  };

  showAdress = (cell, row) => {
    //console.log('row cell', row, cell)
    return row.user.adresse;
  };

  showPhone = (cell, row) => {
    //console.log('row cell', row, cell)
    return row.user.phone;
  };

  showBadge = (cell, row) => {
    //console.log('row cell', row, cell)
    return row.user.badge;
  };

  showDateNaissance = (cell, row) => {
    //console.log('row cell', row, cell)
    return row.user.date_naissance;
  };

  showCertificat = (cell, row) => {
    //console.log('row cell', row, cell)
    return row.user.certificate ? "Oui" : "Non";
  };
  showCertificatFilter = (cell, row) => {
    //console.log('row cell', row, cell)
    return row.user.certificate;
  };

  showDateDebut = (cell, row) => {
    return row.user.date_debut;
  };

  showDateFin = (cell, row) => {
    return row.user.date_fin;
  };

  showMois = (cell, row) => {
    let formatter = new Intl.DateTimeFormat("FR", {
      month: "long",
    });

    //console.log('form', formatter.format(Date.parse(row.created_at)))
    return formatter.format(Date.parse(row.created_at));
  };

  showAnnee = (cell, row) => {
    return moment(row.created_at).format("YYYY");
  };

  formatSchool = (cell, row) => {
    return row?.user?.school;
  };

  showActions = (cell, row) => {
    if (row.user.prolonge) {
      return <Tag color="green">Oui</Tag>;
    } else {
      if (
        !row.user.certificate &&
        row.user.badge >= 1 &&
        row.user.eligible === true
      ) {
        return (
          <div
            role="button"
            onClick={() => this.setState({ show: true, item: row.user })}
            style={{ cursor: "pointer" }}
          >
            <Tag color="red">Non</Tag>
          </div>
        );
      }
    }
    // return row.user.prolonge
  };

  close = () => {
    this.setState({ show: false });

    setTimeout(() => {
      this.setState({ item: {} });
    }, 15000);
  };

  handleSubmit = async () => {
    const url = this.state.api + "prolongement/" + this.state.item.id + "/";
    const config = this.state.requestConfig;
    await axios
      .get(url, config)
      .then((response) => {
        console.log("response", response);
        if (response.data) {
          toast.success(response.data.message);
          this._getListEvaluated();
        }
        this.close();
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  render() {
    const { identifiedList } = this.state;
    // console.log('idt', this.state.identifiedList)
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      noDataText: "Aucune donnée disponible",
      btnGroup: this.createCustomButtonGroup,
      exportCSVText: "Télécharger les fiches d’identification",
    };

    return (
      <div className="liste-identification-component">
        <div className="container-fluid body-background-dashboard">
          <div className="row">
            {/* Navbar Mobile */}
            <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
              <NavbarDashboardMobileAdmin />
            </div>
          </div>

          <HeaderDashboardAdmin />

          <div className="table-row row">
            {/* Navbar Desktop  */}
            {/* <div className="col-xl-2 col-lg-3 col-md-12"> */}
            <div className="nav-container">
              <div
                className="navbar-vertical-customisation no-view-mobile"
                style={{ top: "40px" }}
              >
                <NavbarVerticalAdmin />
              </div>
            </div>

            <div className="col-xl-2 col-lg-3 col-md-12"></div>

            <div className=" col-xl-10 col-lg-9 col-md-12 p-t-25 content-position-after-header px-md-3 px-0">
              <div className="container-fluid">
                <div className="card-dashboard p-l-0 p-r-0">
                  <div className="col">
                    <div className="row m-b-50">
                      <div className="col-xl-7 col-lg-6 col-md-6 col-sm-12">
                        {/* <p className="titre-card-dashboard-sans-padding m-b-15">Liste des apprenant identifiés <span className="certified-count"> {`( ${this.state.userIndentifications.length} )`} </span> </p> */}
                        <p className="titre-card-dashboard-sans-padding m-b-15">
                          Liste des apprenant identifiés{" "}
                          <span className="certified-count">
                            {" "}
                            {`( ${identifiedList.length} )`}{" "}
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                    {/* <from>
                      <button className="btn-theme py-2 px-4">
                        <FeatherIcon
                          className="icon-btn-add-dashboard-orange fs-15 fw-400"
                          icon="download"
                        />
                        Télécharger les fiches
                      </button>
                    </from> */}
                  </div>

                  <div className="table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm">
                    {this.state.load && <ListeIdentificationSkeleton />}
                    {!this.state.load && (
                      <div className="table-container">
                        <BootstrapTable
                          ref="table"
                          data={identifiedList}
                          striped={true}
                          hover={true}
                          condensed={true}
                          multiColumnSort={2}
                          exportCSV
                          search={false}
                          options={options}
                          pagination
                          version="4"
                          bordered={false}
                        >
                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                            }}
                            width="60"
                            dataField="id"
                            isKey={true}
                          >
                            N°
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                            }}
                            width="300"
                            dataAlign="center"
                            dataField="FullName"
                            csvFormat={this.showFullname}
                            dataSort={true}
                            dataFormat={this.showFullname}
                          >
                            Prénom & Nom
                          </TableHeaderColumn>

                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                              textAlign: "center",
                            }}
                            filter={{ type: "TextFilter" }}
                            filterValue={this.showMois}
                            dataAlign="center"
                            width="200"
                            dataField="Month"
                            csvFormat={this.showMois}
                            dataSort={true}
                            dataFormat={this.showMois}
                          >
                            Mois
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                              textAlign: "center",
                            }}
                            filter={{ type: "TextFilter" }}
                            filterValue={this.showAnnee}
                            dataAlign="center"
                            width="170"
                            dataField="Year"
                            csvFormat={this.showAnnee}
                            dataSort={true}
                            dataFormat={this.showAnnee}
                          >
                            Année
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                            }}
                            filter={{ type: "TextFilter" }}
                            dataAlign="center"
                            width="230"
                            dataField="pays_provenance"
                          >
                            Pays de provenance
                          </TableHeaderColumn>

                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                              textAlign: "center",
                            }}
                            width="130"
                            dataAlign="center"
                            dataField="Sexe"
                            csvFormat={this.showGender}
                            dataSort={true}
                            dataFormat={this.showGender}
                          >
                            Genre
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                            }}
                            width="300"
                            dataField="Email"
                            csvFormat={this.showEmail}
                            dataAlign="center"
                            dataSort={true}
                            dataFormat={this.showEmail}
                          >
                            Adresse Email
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                            }}
                            width="200"
                            dataField="Adresse"
                            csvFormat={this.showAdress}
                            dataAlign="center"
                            dataSort={true}
                            dataFormat={this.showAdress}
                          >
                            Adresse
                          </TableHeaderColumn>

                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                            }}
                            width="200"
                            dataField="Phone"
                            csvFormat={this.showPhone}
                            dataAlign="center"
                            dataSort={true}
                            dataFormat={this.showPhone}
                          >
                            Téléphone
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                            }}
                            width="250"
                            dataField="Date_De_Naissance"
                            csvFormat={this.showDateNaissance}
                            dataAlign="center"
                            dataSort={true}
                            dataFormat={this.showDateNaissance}
                          >
                            Date de naissance
                          </TableHeaderColumn>

                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                              textAlign: "center",
                            }}
                            width="100"
                            dataField="Badge"
                            csvFormat={this.showBadge}
                            dataSort={true}
                            dataFormat={this.showBadge}
                          >
                            Badges
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                              textAlign: "center",
                            }}
                            width="120"
                            dataField="certificate"
                            csvFormat={this.showCertificat}
                            dataAlign="center"
                            dataSort={true}
                            dataFormat={this.showCertificat}
                            filterValue={this.showCertificatFilter}
                            filter={{
                              type: "SelectFilter",
                              options: optionFilter,
                            }}
                          >
                            Certificat
                          </TableHeaderColumn>

                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                              textAlign: "center",
                            }}
                            width="120"
                            dataAlign="center"
                            dataField="migrant"
                          >
                            Migrant
                          </TableHeaderColumn>
                          {/* <TableHeaderColumn
                    thStyle={{ fontWeight: 600, fontSize: 16, color: '#1F497D', border: 'none' }}
                    tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none' }}
                    filter={{ type: 'TextFilter' }} dataAlign="center"
                    width="230" dataField='pays_provenance'>Pays de provenance</TableHeaderColumn> */}
                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                            }}
                            width="150"
                            dataAlign="center"
                            dataField="profession"
                          >
                            Profession
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                            }}
                            width="250"
                            dataAlign="center"
                            dataField="situation_eco"
                          >
                            Situation économique
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                              textAlign: "center",
                            }}
                            width="250"
                            dataAlign="center"
                            dataField="revenu_jour"
                          >
                            Revenu journalier
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                            }}
                            width="150"
                            dataAlign="Date_Debut"
                            csvFormat={this.showDateDebut}
                            dataField="Date_Debut"
                            dataSort={true}
                            dataFormat={this.showDateDebut}
                          >
                            Début
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                            }}
                            width="150"
                            dataAlign="center"
                            dataField="Date_fin"
                            dataSort={true}
                            dataFormat={this.showDateFin}
                          >
                            Fin
                          </TableHeaderColumn>

                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                            }}
                            width="150"
                            dataAlign="center"
                            dataField="secteur"
                          >
                            Secteur
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                              textAlign: "center",
                            }}
                            width="200"
                            dataAlign="center"
                            dataField="nbr_employe"
                          >
                            Nbr.d’employés
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                              textAlign: "center",
                            }}
                            width="320"
                            dataAlign="center"
                            dataField="nbr_employe_temp"
                          >
                            Nbr. employés temporaires
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                            }}
                            width="300"
                            dataAlign="center"
                            dataField="type_energie"
                          >
                            Type d’énergie
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                            }}
                            width="300"
                            dataAlign="center"
                            dataField="niveau_etude"
                          >
                            niveau d’étude
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                            }}
                            width="300"
                            dataAlign="center"
                            dataField="school"
                            dataFormat={(cell, row) =>
                              this.formatSchool(cell, row)
                            }
                          >
                            Établissement
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                            }}
                            width="300"
                            dataAlign="center"
                            dataField="nom_diplome"
                          >
                            Certificat/diplôme officiel
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                            }}
                            width="150"
                            dataAlign="center"
                            dataField="#"
                            dataFormat={this.showActions}
                          >
                            Prolongement
                          </TableHeaderColumn>
                        </BootstrapTable>
                      </div>
                    )}

                    <Modal show={this.state.show} onHide={this.close}>
                      {/* <Modal.Header>
                        <Modal.Title>Modal Title</Modal.Title>
                      </Modal.Header> */}
                      <Modal.Body>
                        Offrir une prolongation de 3 semaines à
                        {
                          <b>
                            {" "}
                            {this.state.item.first_name +
                              " " +
                              this.state.item.last_name}{" "}
                          </b>
                        }
                        afin qu'il puisse terminer les trois modules et tenter
                        d’obtenir son certificat
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          onClick={this.handleSubmit}
                          appearance="primary"
                        >
                          Prolonger
                        </Button>
                        <Button onClick={this.close} appearance="subtle">
                          Annuler
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade showUserInfosModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content modal-card">
                <div className="row m-b-35">
                  <div className="col-10 itm-center">
                    <p className="title-modal">
                      {" "}
                      {this.state.userInfos.first_name +
                        " " +
                        this.state.userInfos.last_name}{" "}
                    </p>
                  </div>
                  <div className="col-2">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">
                        <FeatherIcon icon="x" />
                      </span>
                    </button>
                  </div>
                </div>

                {this.state.isIdentified === false && (
                  <div className="row no-identified-row">
                    <div className="col-md-12 no-identified-col">
                      <div className="alert alert-danger gfa-alert-danger">
                        {`${this.state.userInfos.first_name} ${this.state.userInfos.last_name} n'a pas encore encore rempli la fiche d'identification.`}
                      </div>
                    </div>
                  </div>
                )}
                {this.state.isIdentified === true && (
                  <div className="user-infos-modal-row">
                    <div className="col-md-12 user-infos-modal-col">
                      <span className="user-infos-modal-item-label">
                        1. Etes-vous un Migrant de retour :
                      </span>
                      &nbsp;
                      <span className="user-infos-modal-item-content">
                        {this.state.identificationInfos.migrant}
                      </span>
                    </div>
                    <div className="col-md-12 user-infos-modal-col">
                      <span className="user-infos-modal-item-label">
                        1.1 Si oui, votre pays de provenance :
                      </span>
                      &nbsp;
                      <span className="user-infos-modal-item-content">
                        {this.state.identificationInfos.pays_provenance}
                      </span>
                    </div>
                    <div className="col-md-12 user-infos-modal-col">
                      <span className="user-infos-modal-item-label">
                        2. Quelle est votre profession ou métier ?
                      </span>
                      &nbsp;
                      <span className="user-infos-modal-item-content">
                        {this.state.identificationInfos.profession}
                      </span>
                    </div>
                    <div className="col-md-12 user-infos-modal-col">
                      <span className="user-infos-modal-item-label">
                        3. Quelle est votre situation économique actuelle ?
                      </span>
                      &nbsp;
                      <span className="user-infos-modal-item-content">
                        {this.state.identificationInfos.situation_eco}
                      </span>
                    </div>
                    {(this.state.identificationInfos.situation_eco === "AGR" ||
                      this.state.identificationInfos.situation_eco ===
                        "Salarié(e)") && (
                      <div className="col-md-12 user-infos-modal-col">
                        <span className="user-infos-modal-item-label">
                          3.1 Si vous êtes salarié(e), chef d’AGR, ou en
                          exercice d’une activité quelconque génératrice de
                          revenu indiquez votre revenu journalier ou mensuel
                          approximatif (en chiffres) :
                        </span>
                        &nbsp;
                        <span className="user-infos-modal-item-content">
                          {this.state.identificationInfos.revenu_jour} FCFA
                        </span>
                      </div>
                    )}

                    {this.state.identificationInfos.situation_eco === "AGR" && (
                      <>
                        <div className="col-md-12 user-infos-modal-col">
                          <span className="user-infos-modal-item-label">
                            3.2 Si vous êtes responsable d’AGR, depuis combien
                            de temps avez-vous démarré cette AGR ? :
                          </span>
                          &nbsp;
                          <span className="user-infos-modal-item-content">
                            Depuis le{" "}
                            {formatDate(
                              this.state.identificationInfos.date_debut
                            )}
                          </span>
                        </div>
                        <div className="col-md-12 user-infos-modal-col">
                          <span className="user-infos-modal-item-label">
                            3.3 Si vous êtes responsable d’AGR, dans quel
                            secteur exercez-vous ? :
                          </span>
                          &nbsp;
                          <span className="user-infos-modal-item-content">
                            {this.state.identificationInfos.secteur}
                          </span>
                        </div>
                        <div className="col-md-12 user-infos-modal-col">
                          <span className="user-infos-modal-item-label">
                            3.4 Si vous êtes responsable d’AGR, Avez-vous des
                            employés ? :
                          </span>
                          &nbsp;
                          <span className="user-infos-modal-item-content">
                            {this.state.identificationInfos.employes}
                          </span>
                        </div>
                        {this.state.identificationInfos.employes === "oui" && (
                          <>
                            {/* <div className="col-md-12 user-infos-modal-col">
                                                        <span className="user-infos-modal-item-label">
                                                            3.5 Avez-vous des employés : 
                                                        </span>&nbsp;
                                                        <span className="user-infos-modal-item-content">
                                                            {this.state.userInfos.nbr_employe}
                                                        </span>
                                                    </div> */}
                            <div className="col-md-12 user-infos-modal-col">
                              <span className="user-infos-modal-item-label">
                                3.5 Si vous avez des employés, ils sont combien
                                ? :
                              </span>
                              &nbsp;
                              <span className="user-infos-modal-item-content">
                                {this.state.identificationInfos
                                  .nbr_employe_perm !== null
                                  ? this.state.identificationInfos
                                      .nbr_employe_perm +
                                    " employé(s) permanent(s) "
                                  : "O employé temporaire"}
                                &nbsp;et&nbsp;
                                {this.state.identificationInfos
                                  .nbr_employe_temp !== null
                                  ? this.state.identificationInfos
                                      .nbr_employe_temp +
                                    " employé(s) temporaire(s) ou saisonnier(s)"
                                  : "0 employé temporaire ou saisonnier"}
                              </span>
                            </div>
                          </>
                        )}

                        <div className="col-md-12 user-infos-modal-col">
                          <span className="user-infos-modal-item-label">
                            3.4 Si vous êtes responsable d’AGR, quel type
                            d’énergie utilisez-vous ? :
                          </span>
                          &nbsp;
                          <span className="user-infos-modal-item-content">
                            {this.state.identificationInfos.type_energie}
                          </span>
                        </div>
                      </>
                    )}

                    <div className="col-md-12 user-infos-modal-col">
                      <span className="user-infos-modal-item-label">
                        4. Quel est votre niveau d’étude (niveau le plus élevé)
                        ? :
                      </span>
                      &nbsp;
                      <span className="user-infos-modal-item-content">
                        {this.state.identificationInfos.niveau_etude}
                      </span>
                    </div>

                    <div className="col-md-12 user-infos-modal-col">
                      <span className="user-infos-modal-item-label">
                        5. Avez-vous obtenu un certificat/diplôme officiel :
                      </span>
                      &nbsp;
                      <span className="user-infos-modal-item-content">
                        {this.state.identificationInfos.diplome}
                      </span>
                    </div>
                    {this.state.identificationInfos.diplome === "oui" && (
                      <div className="col-md-12 user-infos-modal-col">
                        <span className="user-infos-modal-item-label">
                          6. Nom du diplôme obtenu :
                        </span>
                        &nbsp;
                        <span className="user-infos-modal-item-content">
                          {this.state.identificationInfos.nom_diplome}
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="">
            <MessageAllPage />
          </div>
        </div>
      </div>
    );
  }
}
