import React, { Component } from "react";
import FeatherIcon from "feather-icons-react";
import MessageAllPage from "./components/MessageAllPage";
import NavbarDashboardMobileAdmin from "./components/NavbarDashboardMobile";
import HeaderDashboardAdmin from "./components/HeaderDashboard";
import NavbarVerticalAdmin from "./components/NavbarVertical";
import { toast } from "react-toastify";
// import ModalDetailsApprenant from "./components/ModalDetailsApprenant";
import BarChart from "./components/BarChart";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
  ButtonGroup,
} from "react-bootstrap-table";
import $ from "jquery";
import api from "./../http/global-vars";
import axios from "axios";
import CertificatSkeleton from "./AdminSkeletons/CertificatSkeleton";
import ModalCertificat from "./components/ModalCertificat";
import CertificationDoughnutChart from "./components/CertificationDoughnutChart";
import baseUrl from "../http/backend-base-url";

export default class QuizApprenants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      apprenants: [],
      nbreHommes: 0,
      nbreFemmes: 0,
      ageMoyenne: 0,
      isPageLoading: true,
      apprenantInfos: {},
      apprenantsCertif: [],
      data: {},
      list_student: [],
      students: [],
      load: false,
      isErrorRegenerate: false,
      isSuccessRegenerate: false,
    };
  }

  componentDidMount() {
    // this._getInfosApprenants();
    $(".react-bs-table-search-form input.form-control").attr(
      "placeholder",
      "Chercher"
    );
    // this.onGetApprenantsInfos();
    this._getInfoPerf();
  }

  handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => this.handleClearButtonClick(onClick)}
      />
    );
  };

  _getInfoPerf = async () => {
    let { api, requestConfig } = this.state;
    let url = api + "apprenants_info_performance/";
    this.setState({ isPageLoading: true, load: true });
    try {
      const req = await axios.get(url, requestConfig);
      // console.log("new resp=>", req["data"]?.data?.list_student);
      // console.log("new resp=>", req?.data);
      this.setState({
        students: req?.data?.results,
        isPageLoading: false,
        load: false,
      });
    } catch (error) {
      console.log("err=>", error.message);
      this.setState({ isPageLoading: false, load: false });
    }
  };

  // _getInfosApprenants = async () => {
  //     let { api, requestConfig } = this.state;
  //     let url = api + "apprenants_info/";
  //     this.setState({
  //         isPageLoading: true,
  //     });
  //     try {
  //         const req = await axios.get(url, requestConfig);
  //         console.log("apprenants infos==>", req["data"]);
  //         let list_student = req["data"]?.data?.list_student;
  //         this.setState({
  //             list_student,
  //             apprenantsCertif: req["data"]?.data?.list_student.filter((f) => f.certification === true),
  //             // isPageLoading: false,
  //         });
  //         // this.setState({
  //         //     isPageLoading: false
  //         // })
  //     } catch (error) {
  //         console.log(error.message);
  //         this.setState({
  //             // isPageLoading: false,
  //         });
  //     }
  // };

  // onGetApprenantsInfos = () => {
  //     var api = this.state.api;
  //     var config = this.state.requestConfig;
  //     axios
  //         .get(api + "certificat_info/", config)
  //         .then((response) => {
  //             // console.log(response.data.data.list_student)

  //             this.setState({
  //                 apprenants: response.data.data.list_student,
  //                 nbreHommes: response.data.data.homme,
  //                 nbreFemmes: response.data.data.femme,
  //                 ageMoyenne: response.data.data.moyenne_age,
  //                 apprenantInfos: [],
  //             });

  //             this.setState({
  //                 isPageLoading: false,
  //             });
  //         })
  //         .catch((error) => {
  //             this.setState({
  //                 isPageLoading: false,
  //             });
  //             console.log(error);
  //         });
  // };

  fullNameFormatter = (cell, row) => {
    return (
      <>
        <span
          data-toggle="modal"
          data-target={".modalDetailsApprenant" + row.id}
          onClick={(e) => this.getRowApprenantInfos(row)}
          className="apprenant_fullname linkable"
        >
          {" "}
          {row.first_name + " " + row.last_name}{" "}
        </span>
        <div
          className={"modal fade modalDetailsApprenant" + row.id}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content card-dashboard p-b-100">
              <ModalCertificat
                apprenantInfos={this.state.apprenantInfos}
                id={this.state?.apprenantInfos?.id}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  getRowApprenantInfos = (row) => {
    this.setState({
      apprenantInfos: row,
    });
  };

  createCustomButtonGroup = (props) => {
    return (
      <ButtonGroup className="export-btn" sizeClass="btn-group-md">
        {props.exportCSVBtn}
      </ButtonGroup>
    );
  };

  onSendCertificat = (apprenant) => {
    if (window.confirm("Confirmez l'action")) {
      // console.log(apprenant);

      // console.log("array", [...this.state.itemToActive, ...array]);
      var api = this.state.api;
      var config = this.state.requestConfig;
      var apprenantID = apprenant.id;
      $(".spinnable" + apprenant.id).html(
        '<span className="fas fa-spin fa-spinner"></span>'
      );

      axios
        .get(`${api}sendcertificat/${apprenantID}/`, config)
        .then((response) => {
          var icon =
            '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-hash regenerate-apprenant-password-icon"><g><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></g></svg>';
          $(".spinnable" + apprenant.id).html(icon);
          if (response.status === 200 || response.status === 201) {
            toast.success("Le certificat de l'apprenant a été bien envoyé.");
            console.log("response", response);
            this.setState({
              isSuccessRegenerate: true,
            });
            setTimeout(() => {
              this.setState({
                isSuccessRegenerate: false,
              });
            }, 5000);
          }
        })
        .catch((error) => {
          var icon =
            '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-hash regenerate-apprenant-password-icon"><g><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></g></svg>';
          $(".spinnable" + apprenant.id).html(icon);
          console.log(error);
          if (error?.response?.data?.message) {
            toast.error(error.response.data.message);
          } else {
            toast.error(
              "Une erreur est survnue. Veuillez réessayer plus tard."
            );
          }

          this.setState({
            isErrorRegenerate: true,
          });
          setTimeout(() => {
            this.setState({
              isErrorRegenerate: false,
            });
          }, 5000);
        });
    }
  };

  onActionFormatter = (cell, row) => {
    return (
      <div className="actions-buttons-container d-flex justify-content-between">
        <button
          className={
            "btn btn-primary mr-1 gfa-btn-action-primary spinnable " + row.id
          }
          data-toggle="modal"
          title="Envoyer le certificat"
          onClick={() => this.onSendCertificat(row)}
        >
          <FeatherIcon
            icon="send"
            className="regenerate-apprenant-password-icon"
          />
        </button>
      </div>
    );
  };

  certificatFormatter = (cell, row) => {
    return baseUrl + "/uploads/" + cell;
  };

  render() {
    let { students, load } = this.state;

    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      noDataText: "Aucune donnée disponible",
      btnGroup: this.createCustomButtonGroup,
    };

    return (
      <div className="container-fluid body-background-dashboard">
        <div className="row">
          {/* Navbar Mobile */}
          <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
            <NavbarDashboardMobileAdmin />
          </div>
        </div>

        <HeaderDashboardAdmin />

        <div className="row">
          {/* Navbar Desktop  */}
          {/* <div className="col-xl-2 col-lg-3 col-md-12"> */}
          <div className="nav-container">
            <div
              className="navbar-vertical-customisation no-view-mobile"
              style={{ top: "40px" }}
            >
              <NavbarVerticalAdmin />
            </div>
          </div>

          <div className="col-xl-2 col-lg-3 col-md-12"></div>

          <div className=" col-xl-10 col-lg-9 col-md-12 p-t-25 content-position-after-header px-md-3 px-0">
            <div className="container-fluid">
              <div className="row m-b-30">
                <div className="col-xl-6">
                  <h5 className="text-black f-f-work fw-500 m-b-15">
                    Certificat
                  </h5>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-5 col-lg-5 col-md-5 d-flex">
                  <div className="card-dashboard w-full m-b-15 p-b-20 p-t-50">
                    <CertificationDoughnutChart
                      list_student={students}
                      load={load}
                    />
                  </div>
                </div>
                <div className="col-xl-7 col-lg-7 col-md-7">
                  <div className="card-dashboard w-full m-b-15 p-b-20 p-t-50">
                    <BarChart load={load} />
                  </div>
                </div>
              </div>

              <div className="card-dashboard p-l-0 p-r-0">
                <div className="col">
                  <div className="row m-b-50">
                    <div className="col-xl-7 col-lg-6 col-md-6 col-sm-12">
                      <p className="titre-card-dashboard-sans-padding m-b-15">
                        Liste des apprenants certifiés{" "}
                        <span className="certified-count">
                          {`( ${students?.length} )`}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm">
                  {this.state.load && <CertificatSkeleton />}
                  {!this.state.load && (
                    <div className="table-container">
                      <BootstrapTable
                        data={students}
                        striped={true}
                        hover={true}
                        condensed={true}
                        multiColumnSort={2}
                        options={options}
                        exportCSV
                        pagination
                        search={true}
                        version="4"
                      >
                        <TableHeaderColumn
                          thStyle={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#1F497D",
                            border: "none",
                          }}
                          tdStyle={{
                            fontWeight: 500,
                            fontSize: 14,
                            color: "rgba(0, 0, 0, 0.7)",
                            border: "none",
                          }}
                          dataField="id"
                          isKey={true}
                          hidden={true}
                        >
                          Numero
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#1F497D",
                            border: "none",
                          }}
                          tdStyle={{
                            fontWeight: 500,
                            fontSize: 14,
                            color: "rgba(0, 0, 0, 0.7)",
                            border: "none",
                          }}
                          filter={{ type: "TextFilter", delay: 1000 }}
                          dataSort={true}
                          dataField="first_name"
                          dataFormat={this.fullNameFormatter.bind(this)}
                        >
                          Premon & Nom
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#1F497D",
                            border: "none",
                          }}
                          tdStyle={{
                            fontWeight: 500,
                            fontSize: 14,
                            color: "rgba(0, 0, 0, 0.7)",
                            border: "none",
                          }}
                          filter={{ type: "TextFilter", delay: 1000 }}
                          dataSort={true}
                          dataField="civilite"
                        >
                          Civilité
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#1F497D",
                            border: "none",
                          }}
                          tdStyle={{
                            fontWeight: 500,
                            fontSize: 14,
                            color: "rgba(0, 0, 0, 0.7)",
                            border: "none",
                          }}
                          filter={{ type: "TextFilter", delay: 1000 }}
                          dataSort={true}
                          dataField="phone"
                        >
                          Téléphone
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#1F497D",
                            border: "none",
                          }}
                          tdStyle={{
                            fontWeight: 500,
                            fontSize: 14,
                            color: "rgba(0, 0, 0, 0.7)",
                            border: "none",
                          }}
                          filter={{ type: "TextFilter", delay: 1000 }}
                          dataSort={true}
                          width="200"
                          dataField="certification"
                        >
                          Certification
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#1F497D",
                            border: "none",
                          }}
                          tdStyle={{
                            fontWeight: 500,
                            fontSize: 14,
                            color: "rgba(0, 0, 0, 0.7)",
                            border: "none",
                          }}
                          filter={{ type: "TextFilter", delay: 1000 }}
                          dataSort={true}
                          width="300"
                          dataField="email"
                        >
                          Adresse e-mail
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#1F497D",
                            border: "none",
                          }}
                          tdStyle={{
                            fontWeight: 500,
                            fontSize: 14,
                            color: "rgba(0, 0, 0, 0.7)",
                            border: "none",
                          }}
                          dataSort={true}
                          width="300"
                          dataField="certificat_url"
                          dataFormat={this.certificatFormatter}
                          csvFormat={this.certificatFormatter}
                          hidden
                          export={true}
                        >
                          Certificat
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#1F497D",
                            border: "none",
                          }}
                          tdStyle={{
                            fontWeight: 500,
                            fontSize: 14,
                            color: "rgba(0, 0, 0, 0.7)",
                            border: "none",
                          }}
                          width="100"
                          dataField="id"
                          dataFormat={this.onActionFormatter}
                          export={false}
                        >
                          Action
                        </TableHeaderColumn>
                      </BootstrapTable>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <MessageAllPage />
        </div>
      </div>
    );
  }
}

const style = {
  card: {
    background: "#FFFFFF",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
    borderRadius: "5px",
    padding: "20px 25px 35px 20px",
    maxheight: "100%",
  },
};
