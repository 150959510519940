import React from "react";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import isAuthenticate from "./modules/Secure/Secure";
// import Accueil from './frontend/Accueil';
import Connexion from "./frontend/Connexion";
import AllCourses from "./frontend/dashboards/AllCoursesV2";
import Downloads from "./frontend/dashboards/Downloads";
import Forum from "./frontend/dashboards/Forum";
import MyCourses from "./frontend/dashboards/MyCourses";
import QuizCours from "./frontend/dashboards/QuizCours";
import SessionDetails from "./frontend/dashboards/SessionDetails";
import SessionStart from "./frontend/dashboards/SessionStart";
// import MyCertificatV2 from './frontend/dashboards/MyCertificatesV2';
import MyCertificatesV3 from "./frontend/dashboards/MyCertificatesV3";
// import MyCertificates from './frontend/dashboards/MyCertificates';
import Apprenants from "./backend/Apprenants";
import Module from "./backend/Module";
import Quiz from "./backend/Quiz";
import TableauDeBord from "./backend/TableauDeBord";
import ForumDetail from "./frontend/dashboards/ForumDetail";
//import ModuleDetails from './backend/ModuleDetails';
import "react-toastify/dist/ReactToastify.css";
import Certificat from "./backend/Certificat";
import CreateQcm from "./backend/components/CreateQcm";
import ModuleDetailsV2 from "./backend/ModuleDetailsV2";
import FicheEvaluation from "./frontend/components/FicheEvaluation";
import FicheIdentificaton from "./frontend/components/FicheIdentification";
import MyProfile from "./frontend/dashboards/MyProfile";
import SliderContentDetailsSession from "./frontend/dashboards/SliderContentDetailsSession";
import ResetPassword from "./frontend/ResetPassword";
import TokenExpirated from "./frontend/TokenExpirated";
import WelcomeV3 from "./frontend/WelcomeV3";
import isFirstConnexion from "./modules/Permission/Permission";
// import ScrollToTop from './ScrollToTop';
// import { Switch } from '@material-ui/core';
import { isMobile } from "react-device-detect";
import AdminContactMessages from "./backend/AdminContactMessages";
import MobileDetect from "./modules/MobileDetect/MobileDetect";
// import ListeIdentification from './backend/ListeIdentification';
import { ToastContainer } from "react-toastify";
import packageJson from "../package.json";
import AdminUserManager from "./backend/AdminUserManager";
import Extraction from "./backend/Extraction";
import ListeIdentificationV2 from "./backend/ListeIdentificationV2";
import Performances from "./backend/Performances";
import withClearCache from "./ClearCache";
import Inscription from "./frontend/Inscription";
import LegalNotice from "./frontend/LegalNotice";
import PrivacyPolicy from "./frontend/PrivacyPolicy";
import WelcomeV2 from "./frontend/WelcomeV2";
import { getBuildDate } from "./utils/utils";
// import { useDispatch } from "react-redux";
// import { fetchListApprenants } from "./redux/apprenantList/apprenantListAction";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp() {
  console.log("Build date:", getBuildDate(packageJson.buildDate));
  var isLogInExpired = isAuthenticate();
  var isFirst = isFirstConnexion();
  // const dispatch = useDispatch();
  const token = window.localStorage.getItem("userToken");
  var userType = window.localStorage.getItem("userType");
  console.log("Type user", userType);

  // useEffect(() => {
  //   if (userType === "admin" && token) {
  //     dispatch(fetchListApprenants());
  //   }
  // }, [token]);

  if (isMobile) {
    return <MobileDetect />;
  } else {
    return (
      <Router>
        <div className="router-container">
          <ToastContainer />
          <Switch>
            {/* <Route exact path="/" component={Accueil} /> */}
            <Route exact path="/" component={WelcomeV2} />
            <Route
              path="/politique-de-confidentialite"
              component={PrivacyPolicy}
            />
            <Route path="/mentions-legales" component={LegalNotice} />
            {/*  <Route path="/welcome" component={WelcomeV3} /> */}
            <Route path="/connexion" component={Connexion} />
            <Route path="/inscription" component={Inscription} />
            <Route
              path="/modifier-mon-mot-de-passe"
              component={ResetPassword}
            />

            <Route path="/welcome">
              {isLogInExpired || !token ? (
                <Redirect to="/connexion" />
              ) : (
                <WelcomeV3 />
              )}
            </Route>

            <Route path="/tous-les-modules">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : isFirst ? (
                <Redirect to={`/welcome`} />
              ) : (
                <AllCourses />
              )}
            </Route>

            <Route path="/mon-parcours">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : isFirst ? (
                <Redirect to={`/welcome`} />
              ) : (
                <MyCourses />
              )}
            </Route>
            <Route path="/telechargements">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : isFirst ? (
                <Redirect to={`/welcome`} />
              ) : (
                <Downloads />
              )}
            </Route>
            <Route path="/forum">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : isFirst ? (
                userType === "admin" ? (
                  <Forum />
                ) : (
                  <Redirect to={`/welcome`} />
                )
              ) : (
                <Forum />
              )}
            </Route>
            <Route path="/mon-profile">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : isFirst ? (
                <Redirect to={`/welcome`} />
              ) : (
                <MyProfile />
              )}
            </Route>
            <Route
              path="/cours-details/:id"
              component={SessionDetails}
              render={() => {
                if (isLogInExpired) {
                  return <Redirect to={`/connexion`} />;
                } else if (isFirst === true) {
                  return <Redirect to={`/welcome`} />;
                } else {
                  return <SessionDetails />;
                }
              }}
            />
            {/* <Route path="/cours-details/:id">
            {isLogInExpired ? <Redirect to="/connexion" /> : <SessionDetails />}
          </Route> */}
            <Route path="/cours-start/:id">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : isFirst === true ? (
                <Redirect to={`/welcome`} />
              ) : (
                <SessionStart />
              )}
            </Route>
            <Route path="/quiz-cours">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : isFirst === true ? (
                <Redirect to={`/welcome`} />
              ) : (
                <QuizCours />
              )}
            </Route>
            <Route path="/mon-certificat">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : isFirst === true ? (
                <Redirect to={`/welcome`} />
              ) : (
                <MyCertificatesV3 />
              )}
            </Route>
            <Route path="/fiche-identification">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : (
                <FicheIdentificaton />
              )}
            </Route>
            <Route path="/fiche-evaluation">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : isFirst ? (
                <Redirect to={`/welcome`} />
              ) : (
                <FicheEvaluation />
              )}
            </Route>
            <Route
              path="/details-forum/:id"
              component={ForumDetail}
              render={() => {
                if (isLogInExpired) {
                  return <Redirect to={`/connexion`} />;
                } else if (isFirst === true) {
                  return <Redirect to={`/welcome`} />;
                } else {
                  return <ForumDetail />;
                }
              }}
            />
            <Route
              path="/session-start/:component/:id"
              component={SliderContentDetailsSession}
              render={() => {
                if (isLogInExpired) {
                  return <Redirect to={`/connexion`} />;
                } else if (isFirst === true) {
                  return <Redirect to={`/welcome`} />;
                } else {
                  return <SliderContentDetailsSession />;
                }
              }}
            />
            {/* <Route path="/session-start/:id">
            {isLogInExpired ? (
              <Redirect to="/connexion" />
            ) : (
              <SliderContentDetailsSession />
            )}
          </Route> */}
            <Route path="/token-expire" component={TokenExpirated} />

            {/* Routing Backend  */}
            <Route path="/tableau-de-bord">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : (
                <TableauDeBord />
              )}
            </Route>

            <Route path="/performances">
              {isLogInExpired ? <Redirect to="/connexion" /> : <Performances />}
            </Route>

            <Route path="/extraction">
              {isLogInExpired ? <Redirect to="/connexion" /> : <Extraction />}
            </Route>

            <Route path="/gestion-messages-contact">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : (
                <AdminContactMessages />
              )}
            </Route>

            <Route path="/apprenants">
              {isLogInExpired ? <Redirect to="/connexion" /> : <Apprenants />}
            </Route>

            <Route path="/parametres">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : (
                <AdminUserManager />
              )}
            </Route>

            <Route path="/liste-des-identifications">
              {isLogInExpired ? (
                <Redirect to="/connexion" />
              ) : (
                <ListeIdentificationV2 />
              )}
            </Route>

            <Route path="/quiz-admin">
              {isLogInExpired ? <Redirect to="/connexion" /> : <Quiz />}
            </Route>
            <Route path="/module">
              {isLogInExpired ? <Redirect to="/connexion" /> : <Module />}
            </Route>
            {/* <Route exact path="/module-details/:id">
            {isLogInExpired ? <Redirect to="/connexion" /> : <ModuleDetailsV2/>}
          </Route> */}
            <Route
              path="/module-details/:id"
              component={ModuleDetailsV2}
              render={() => {
                if (isLogInExpired) {
                  return <Redirect to={`/connexion`} />;
                } else {
                  return <ModuleDetailsV2 />;
                }
              }}
            />
            {/* <Route exact path="/module-details/:id" component={ModuleDetails} /> */}

            <Route path="/qcm">
              {isLogInExpired ? <Redirect to="/connexion" /> : <CreateQcm />}
            </Route>

            <Route path="/certificats">
              {isLogInExpired ? <Redirect to="/connexion" /> : <Certificat />}
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
