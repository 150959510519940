import React from "react";
import HeaderAccueilV2 from "./components/HeaderAccueilV2";
import MessageAllPage from "../backend/components/MessageAllPage";
// import ContactSupport from './components/ContactSupport';
import { Redirect } from "react-router-dom";
import { Book } from "@material-ui/icons";

function WelcomeV2() {
  // console.log('tok', window.localStorage.getItem('userToken'))
  const userType = window.localStorage.getItem("userType");
  //console.log(userType)
  if (!window.localStorage.getItem("userToken")) {
    return (
      <div>
        <HeaderAccueilV2 />
        <div className="bg-white p-t-100">
          <div className="container">
            {/* Première section */}
            <div className="row">
              <div className="col-md-8">
                {/* Contenu gauche */}
                <h1 className="" style={{ marginBottom: 30, marginTop: 10 }}>
                  Bakeli Learning
                </h1>
                <p className="">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Facilis praesentium illum, iure aspernatur nisi et voluptates
                  odit ea sit nesciunt provident corrupti nemo nobis quam
                  suscipit! Officia in error aperiam!
                </p>
                <div
                  style={{
                    height: 2,
                    width: "100%",
                    backgroundColor: "#9995",
                    margin: "30px 0",
                  }}
                />
                <p className="">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum
                  consequatur officiis, sed molestiae corrupti in nemo iste
                  soluta, veritatis qui debitis, velit eveniet? Iure dolores
                  numquam officiis officia voluptas ut?
                </p>
              </div>
              <div className="col-md-4">
                {/* Image à droite */}
                <img
                  src="./img/landing_page/2t.png"
                  alt="user"
                  className="img-fluid"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            {/* Seconde section */}
            {/* <div className="row" style={{ margin: "50px 0" }}>
              <div className="col-md-12">
                <div
                  style={{
                    borderRadius: 20,
                    padding: 20,
                    backgroundColor: "#9992",
                  }}
                >
                  <Book style={{ fontSize: 50 }} />
                  <h3 style={{ margin: "10px 0" }}>Communication</h3>
                  <p className="text-sm">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dolores, nemo reiciendis incidunt sapiente voluptatem
                    laboriosam numquam rerum nam autem suscipit quasi maiores
                    nostrum, neque blanditiis ea fugiat ipsam debitis?
                    Asperiores.
                  </p>
                </div>
              </div>
            </div> */}

            {/* Troisième section */}
            <div className="row gap-1 p-4" style={{ marginTop: 50 }}>
              <div
                className="col-md-4 col-sm-6 col-xs-12"
                // style={{
                //   margin: "10px 0",
                //   border: "1px solid #9993",
                //   borderRadius: 10,
                //   padding: "20px 10px",
                // }}
              >
                <div
                  className=""
                  style={{
                    margin: "10px 0",
                    border: "1px solid #9993",
                    borderRadius: 10,
                    padding: "20px 10px",
                  }}
                >
                  <div className="" style={{ width: "100%", height: 200 }}>
                    <img
                      // className="img-course-on-card-cours"
                      src="./img/landing_page/1.png"
                      alt=""
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "100%",
                        alignSelf: "start",
                      }}
                    />
                  </div>
                  <h3 style={{ margin: "10px 0" }}>Titre de l'element</h3>
                  <p className="" style={{ fontSize: 14 }}>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Expedita eius incidunt aliquid facilis hic ullam.
                  </p>
                </div>
                {/* Premier élément de la troisième section */}
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className=""
                  style={{
                    margin: "10px 0",
                    border: "1px solid #9993",
                    borderRadius: 10,
                    padding: "20px 10px",
                  }}
                >
                  {/* Deuxième élément de la troisième section */}
                  <div className="" style={{ width: "100%", height: 200 }}>
                    <img
                      // className="img-course-on-card-cours"
                      src="./img/landing_page/2.png"
                      alt=""
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                  <h3 style={{ margin: "10px 0" }}>Titre de l'element</h3>
                  <p className="" style={{ fontSize: 14 }}>
                    incidunt aliquid illum magnam neque illo impedit inventore
                    soluta sed accusantium ipsum?
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div
                  className=""
                  style={{
                    margin: "10px 0",
                    border: "1px solid #9993",
                    borderRadius: 10,
                    padding: "20px 10px",
                  }}
                >
                  {/* Troisième élément de la troisième section */}
                  <div className="" style={{ width: "100%", height: 200 }}>
                    <img
                      // className="img-course-on-card-cours"
                      src="./img/landing_page/3.png"
                      alt=""
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                  <h3 style={{ margin: "10px 0" }}>Titre de l'element</h3>
                  <p className="" style={{ fontSize: 14 }}>
                    Aliquid facilis hic ullam, id sunt eveniet quasi mollitia
                    illum magnam neque accusantium ipsum?
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Quatieme section */}
          <div
            className=""
            style={{
              margin: "50px 0",
              padding: 20,
              backgroundColor: "#9991",
            }}
          >
            <div className="container">
              <div className="row ">
                <div className="col-md-4">
                  {/* Image à droite */}
                  <img
                    src="./img/landing_page/4.png"
                    alt="user"
                    className="img-fluid"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="col-md-8">
                  {/* Contenu gauche */}
                  <h1 className="" style={{ marginBottom: 30, marginTop: 10 }}>
                    Dem lene Djangui
                  </h1>
                  <p className="">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Facilis praesentium illum, iure aspernatur nisi et
                    voluptates odit ea sit nesciunt provident corrupti nemo
                    nobis quam suscipit! Officia in error aperiam!
                  </p>
                  <div
                    style={{
                      height: 2,
                      width: "100%",
                      backgroundColor: "#9995",
                      margin: "30px 0",
                    }}
                  />
                  <p className="">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Rerum consequatur officiis, sed molestiae corrupti in nemo
                    iste soluta, veritatis qui debitis, velit eveniet? Iure
                    dolores numquam officiis officia voluptas ut?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="">{/* <MessageAllPage fullWidth={true} /> */}</div>
      </div>
    );
  } else {
    if (userType === "student") {
      return <Redirect to="/tous-les-modules" />;
    } else if (userType === "admin") {
      return <Redirect to="/tableau-de-bord" />;
    } else {
      return false;
    }
  }
}

export default WelcomeV2;
