import React, { Component } from "react";
import FeatherIcon from "feather-icons-react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import ContactSupport from "./ContactSupport";

export default class NavbarVerticalAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClickable = () => {
    $(".contactSupportModal").appendTo($("body"));
  };

  render() {
    return (
      <div className="hoverable" style={{ backgroundColor: "#009688" }}>
        <ul className="nav nav-items-custom flex-column">
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/tableau-de-bord"
              activeClassName="actived"
            >
              <FeatherIcon className="icon-navbar-vertical" icon="grid" />
              <span className="hiddenable-navlink-label">Tableau de bord</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/module"
              activeClassName="actived"
            >
              <FeatherIcon className="icon-navbar-vertical" icon="book" />
              <span className="hiddenable-navlink-label">Module</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/apprenants"
              activeClassName="actived"
            >
              <FeatherIcon className="icon-navbar-vertical" icon="user" />
              <span className="hiddenable-navlink-label">Apprenant</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/liste-des-identifications"
              activeClassName="actived"
            >
              <FeatherIcon className="icon-navbar-vertical" icon="user" />
              <span className="hiddenable-navlink-label">
                Apprenants Identifiés
              </span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/certificats"
              activeClassName="actived"
            >
              <FeatherIcon className="icon-navbar-vertical" icon="award" />
              <span className="hiddenable-navlink-label">Certificats</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/performances"
              activeClassName="actived"
            >
              <FeatherIcon className="icon-navbar-vertical" icon="activity" />
              <span className="hiddenable-navlink-label">Performances</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom "
              to="/parametres"
              activeClassName="actived"
            >
              <FeatherIcon className="icon-navbar-vertical" icon="settings" />
              <span className="hiddenable-navlink-label">Paramétres</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/extraction"
              activeClassName="actived"
            >
              <FeatherIcon className="icon-navbar-vertical" icon="download" />
              <span className="hiddenable-navlink-label">Extraction</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/gestion-messages-contact"
              activeClassName="actived"
            >
              <FeatherIcon
                className="icon-navbar-vertical"
                icon="message-square"
              />
              <span className="hiddenable-navlink-label">Messages</span>
            </NavLink>
          </li>
        </ul>

        {/* <div className="container-fluid">
                    <p className="text-white f-f-inter fw-600 fs-14 m-b-20">Besoin d’aide ?</p>
                    <button onClick={() => this.onClickable()} className="btn-icon-transparent" data-toggle="modal" data-target=".contactSupportModal"><FeatherIcon className="icon-contact-support" icon="mail" /> Contacter le support</button>

                    <div className="modal fade contactSupportModal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content modal-card">
                                <ContactSupport />
                            </div>
                        </div>
                    </div>
                    
                </div> */}
      </div>
    );
  }
}
