import axios from "axios";
import FeatherIcon from "feather-icons-react";
import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { toast } from "react-toastify";
import api from "./../http/global-vars";
import ApprenantSkeleton from "./AdminSkeletons/ApprenantSkeleton";
import HeaderDashboardAdmin from "./components/HeaderDashboard";
import MessageAllPage from "./components/MessageAllPage";
import ModalAddAdmin from "./components/ModalAddAdmin";
import NavbarDashboardMobileAdmin from "./components/NavbarDashboardMobile";
import NavbarVerticalAdmin from "./components/NavbarVertical";
toast.configure();
const data = [
  {
    id: "1",
    fullname: "Moumadjad Ahmed Abdou",
    phone: "77 777 77 77",
    email: "moumadjad@gmail.com",
  },
];

export default class AdminUserManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPageLoading: true,
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      fields: {},
      errors: {},
      isSuccessAddAdmin: false,
      isErrorAddAdmin: false,
      is_empty_email: false,
      is_empty_phone: false,
      is_empty_first_name: false,
      is_empty_last_name: false,
      actionType: "create",
      adminInfos: {},
      load: false,
      authAdmin: {
        first_name: "",
        last_name: "",
        adresse: "",
        email: "",
        sexe: "",
        phone: "",
        id: null,
      },
      do_load: false,
      listAdmins: [],
    };
  }

  componentDidMount() {
    this.onGetAdminListing();
  }

  switchUserEtat = async (e, user) => {
    e.preventDefault();
    console.log("user delete=>", user);
    this.setState({ load: true });
    let formData = new FormData();
    user.is_active = false;
    formData.append("is_active", user?.is_active);
    try {
      const req = await axios.put(api + "user/" + user?.id + "/", formData, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("req=>", req);
      if (req["status"] === 201 || req["status"] === 200) {
        this.setState({ load: false });
      }
    } catch (error) {
      console.log("error=>", error);
      this.setState({ load: false });
    }
  };

  actionTableAdmin = (cell, row) => {
    return (
      <>
        <button
          className="btn-edit-table-admin"
          onClick={() => this.onGetAdminInfos(row)}
          data-toggle="modal"
          data-target="#modalAddAdmin"
        >
          <FeatherIcon icon="edit" />
        </button>

        {this.state.load && (
          <button type="submit" className="btn-theme p-l-90 p-r-90">
            Modification en cours &nbsp;
            <i className="fas fa-spin fa-spinner"></i>
          </button>
        )}
        <button
          className="btn-delete-table-admin"
          onClick={(e) => {
            window.confirm("Veuillez confirmer pour continuer ou annuler") &&
              this.switchUserEtat(e, row);
          }}
        >
          <FeatherIcon icon="archive" />
        </button>
      </>
    );
  };

  openCreateAdminModal = async () => {
    await this.setState({
      actionType: "create",
      adminInfos: {},
    });
    console.log(this.state.actionType);
  };

  onGetAdminInfos = async (adminInfos) => {
    console.log("adminInfos=>", adminInfos);
    await this.setState({
      adminInfos: {},
    });
    await this.setState({
      actionType: "update",
      adminInfos: adminInfos,
    });
    console.log(this.state.actionType);
  };

  onGetAdminListing = () => {
    var api = this.state.api;
    var config = this.state.requestConfig;

    axios
      .get(api + "listadmin/", config)
      .then(async (response) => {
        console.log("list admin=>", response);
        this.setState({
          listAdmins: response.data.reverse(),
          isPageLoading: false,
        });
        await this._getMeInfo();
      })
      .catch((error) => {
        console.log("errors=>", error);
        this.setState({
          isPageLoading: false,
        });
      });
  };

  fullnameFormatter = (cell, row) => {
    return cell + " " + row.last_name;
  };

  _getMeInfo = async () => {
    let url = api + "me";
    let { authAdmin } = this.state;
    try {
      const req = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      });
      // console.log("request response=>", req['data'])
      if (req["status"] === 201 || req["status"] === 200) {
        let data = req["data"].data;
        authAdmin.adresse = data?.adresse;
        authAdmin.last_name = data?.last_name;
        authAdmin.first_name = data?.first_name;
        authAdmin.email = data?.email;
        authAdmin.sexe = data?.sexe;
        authAdmin.phone = data?.phone;
        authAdmin.id = data?.id;
        this.setState(
          { authAdmin }
          // () => console.log("display=>", authAdmin.sexe.charAt(0).toUpperCase() + authAdmin.sexe.slice(1))
        );
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  handleChange = (e) => {
    let { value, name } = e.target;
    let { authAdmin } = this.state;
    authAdmin[name] = value;
    this.setState({ authAdmin });
  };

  onSubmitEdit = async (e) => {
    e.preventDefault();
    this.setState({ do_load: true });

    let { authAdmin } = this.state;
    let url = api + "user/" + authAdmin?.id + "/";
    let formData = new FormData();
    // console.log("admin=>", authAdmin)
    formData.append("first_name", authAdmin.first_name);
    formData.append("last_name", authAdmin.last_name);
    formData.append("adresse", authAdmin.adresse);
    formData.append("email", authAdmin.email);
    formData.append("phone", authAdmin.phone);
    formData.append("sexe", authAdmin.sexe.toLowerCase());
    try {
      const req = await axios.put(url, formData, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          "Content-Type": "multipart/form-data",
        },
      });
      // console.log("req=>", req)
      if (req["status"] === 201 || req["status"] === 200) {
        this.setState({ do_load: false });
        toast.success("Modification enregistrer avec succès", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });

        let { listAdmins } = this.state;
        let index = listAdmins.findIndex((f) => f.id === authAdmin.id);
        if (index !== -1) {
          listAdmins[index] = req["data"];
        }
        this.setState({ listAdmins });
      }
    } catch (error) {
      // console.log("error=>", error)
      this.setState({ do_load: false });
      toast.warning(
        "Une erreur s'est produit lors de l'operation, veuillez réessayer",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        }
      );
    }
  };

  render() {
    let { adminInfos, authAdmin } = this.state;
    return (
      <div className="container-fluid body-background-dashboard">
        <div className="row">
          {/* Navbar Mobile */}
          <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
            <NavbarDashboardMobileAdmin />
          </div>
        </div>

        <HeaderDashboardAdmin />

        <div className="row">
          {/* Navbar Desktop  */}
          {/* <div className="col-xl-2 col-lg-3 col-md-12"> */}
          <div className="nav-container">
            <div
              className="navbar-vertical-customisation no-view-mobile"
              style={{ top: "40px" }}
            >
              <NavbarVerticalAdmin />
            </div>
          </div>

          <div className="col-xl-2 col-lg-3 col-md-12"></div>

          <div className=" col-xl-10 col-lg-9 col-md-12 p-t-25 content-position-after-header px-md-3 px-0">
            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-6">
                  <h5 className="text-black f-f-work fw-500 m-b-15">
                    Paramètres
                  </h5>
                </div>
              </div>

              <div className="card-dashboard py-5 mb-3">
                <form
                  onSubmit={this.onSubmitEdit}
                  encType="multipart/form-data"
                >
                  <div className="row">
                    <div className="col-md-6 pr-md-5">
                      <div className="form-group m-b-40">
                        <label
                          htmlFor="inputMyProfilePrenom"
                          className="label-input-my-profile"
                        >
                          Prénom
                        </label>
                        <div className="pos-relative">
                          <input
                            name="first_name"
                            type="text"
                            className="form-control input-my-profile"
                            id="inputMyProfilePrenom"
                            placeholder="Prénom"
                            value={authAdmin.first_name}
                            onChange={this.handleChange}
                          />
                          <FeatherIcon
                            className="icon-input-my-profile"
                            icon="edit-3"
                          />
                        </div>
                      </div>
                      <div className="form-group m-b-40">
                        <label
                          htmlFor="inputMyProfileNom"
                          className="label-input-my-profile"
                        >
                          Nom
                        </label>
                        <div className="pos-relative">
                          <input
                            name="last_name"
                            type="text"
                            className="form-control input-my-profile"
                            id="inputMyProfileNom"
                            placeholder="Nom"
                            value={authAdmin.last_name}
                            onChange={this.handleChange}
                          />
                          <FeatherIcon
                            className="icon-input-my-profile"
                            icon="edit-3"
                          />
                        </div>
                      </div>
                      <div className="form-group m-b-40">
                        <label
                          htmlFor="inputMyProfileCivilite"
                          className="label-input-my-profile"
                        >
                          Civilité
                        </label>
                        <select
                          name="sexe"
                          value={authAdmin.sexe}
                          className="form-control input-my-profile"
                          id="inputMyProfileCivilite"
                          onChange={this.handleChange}
                        >
                          <option value="homme">Homme</option>
                          <option value="femme">Femme</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 pl-md-5">
                      <div className="form-group m-b-40">
                        <label
                          htmlFor="inputMyProfileEmail"
                          className="label-input-my-profile"
                        >
                          Adresse mail
                        </label>
                        <div className="pos-relative">
                          <input
                            name="email"
                            type="email"
                            className="form-control input-my-profile"
                            id="inputMyProfileEmail"
                            placeholder="Adresse mail"
                            value={authAdmin.email}
                            onChange={this.handleChange}
                          />
                          <FeatherIcon
                            className="icon-input-my-profile"
                            icon="edit-3"
                          />
                        </div>
                      </div>
                      <div className="form-group m-b-40">
                        <label
                          htmlFor="inputMyProfileTelephone"
                          className="label-input-my-profile"
                        >
                          N° téléphone
                        </label>
                        <div className="pos-relative">
                          <input
                            name="phone"
                            type="text"
                            className="form-control input-my-profile"
                            id="inputMyProfileTelephone"
                            placeholder="77 444 55 66"
                            value={authAdmin.phone}
                            onChange={this.handleChange}
                          />
                          <FeatherIcon
                            className="icon-input-my-profile"
                            icon="edit-3"
                          />
                        </div>
                      </div>
                      {/* <div className="form-group m-b-40">
                                                <label
                                                    htmlFor="inputMyProfilePassword"
                                                    className="label-input-my-profile"
                                                >
                                                    Mot de passe
                                            </label>
                                                <div className="pos-relative">
                                                    <input
                                                        name="password"
                                                        type="password"
                                                        className="form-control input-my-profile"
                                                        id="inputMyProfilePassword"
                                                        placeholder="Mot de passe"
                                                    />
                                                    <FeatherIcon
                                                        className="icon-input-my-profile"
                                                        icon="edit-3"
                                                    />
                                                </div>
                                            </div> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 offset-md-6 pl-md-5">
                      {!this.state.do_load ? (
                        <button className="btn-theme fw-500 w-full py-3">
                          ENREGISTRER LES MODIFICATIONS
                        </button>
                      ) : (
                        <button className="btn-theme fw-500 w-full py-3">
                          Modification en cours &nbsp;
                          <i className="fas fa-spin fa-spinner"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>

              <div className="card-dashboard">
                <div className="col">
                  <div className="row m-b-50">
                    <div className="col-xl-7 col-lg-6 col-md-6">
                      <p className=" ml-0 titre-card-dashboard-sans-padding m-b-15">
                        Autres Administrateurs{" "}
                      </p>
                    </div>
                    <div className="col-xl-5 col-lg-6 col-md-6 d-md-flex justify-content-md-end">
                      <button
                        className="btn-add-dashboard"
                        type="button"
                        data-toggle="modal"
                        data-target="#modalAddAdmin"
                        onClick={() => this.openCreateAdminModal()}
                      >
                        <FeatherIcon
                          className="icon-btn-add-dashboard"
                          icon="plus"
                        />
                        Ajouter un administrateur
                      </button>

                      <ModalAddAdmin
                        onRefreshAdminList={this.onGetAdminListing}
                        actionType={this.state?.actionType}
                        updateAdminData={adminInfos}
                      />
                    </div>
                  </div>
                </div>
                <div className="table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm">
                  {this.state.isPageLoading && <ApprenantSkeleton />}
                  {!this.state.isPageLoading && (
                    <div className="table-container mx-0">
                      <BootstrapTable
                        data={this.state.listAdmins}
                        // <BootstrapTable data={data}
                        striped={true}
                        hover={true}
                        condensed={true}
                        multiColumnSort={2}
                        pagination={true}
                        version="4"
                        bordered={false}
                      >
                        <TableHeaderColumn
                          thStyle={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#1F497D",
                            border: "none",
                          }}
                          tdStyle={{
                            fontWeight: 500,
                            fontSize: 14,
                            color: "rgba(0, 0, 0, 0.7)",
                            border: "none",
                          }}
                          dataField="id"
                          isKey={true}
                        >
                          N°
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#1F497D",
                            border: "none",
                          }}
                          tdStyle={{
                            fontWeight: 500,
                            fontSize: 14,
                            color: "rgba(0, 0, 0, 0.7)",
                            border: "none",
                          }}
                          width="300"
                          dataFormat={this.fullnameFormatter}
                          dataField="first_name"
                        >
                          Prénom & Nom
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#1F497D",
                            border: "none",
                          }}
                          tdStyle={{
                            fontWeight: 500,
                            fontSize: 14,
                            color: "rgba(0, 0, 0, 0.7)",
                            border: "none",
                          }}
                          width="300"
                          dataField="phone"
                        >
                          Téléphone
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#1F497D",
                            border: "none",
                          }}
                          tdStyle={{
                            fontWeight: 500,
                            fontSize: 14,
                            color: "rgba(0, 0, 0, 0.7)",
                            border: "none",
                          }}
                          width="300"
                          dataField="email"
                        >
                          Adresse Mail
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#1F497D",
                            border: "none",
                          }}
                          tdStyle={{
                            fontWeight: 500,
                            fontSize: 14,
                            color: "rgba(0, 0, 0, 0.7)",
                            border: "none",
                            paddingTop: 20,
                          }}
                          width="100"
                          dataField="id"
                          dataFormat={this.actionTableAdmin}
                        >
                          Action
                        </TableHeaderColumn>
                      </BootstrapTable>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <MessageAllPage />
        </div>
      </div>
    );
  }
}
