import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";

import ReactGA from "react-ga";
ReactGA.initialize("UA-184919919-1");
ReactGA.pageview(window.location.pathname + window.location.search);

function noop() {}

if (process.env.NODE_ENV !== "development") {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

if (process.env.NODE_ENV === "production") {
  // Sentry.init({
  //   dsn: "https://00b3cc40a5004c2a98fbb9c7db0563c7@app-sentry.withvolkeno.com/6",
  //   autoSessionTracking: true,
  //   integrations: [new Integrations.BrowserTracing()],
  //   // We recommend adjusting this value in production, or using tracesSampler
  //   // for finer control
  //   tracesSampleRate: 1.0,
  // });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
